import { template as template_ad155918b43547cf8b091c91469f2bdb } from "@ember/template-compiler";
const SidebarSectionMessage = template_ad155918b43547cf8b091c91469f2bdb(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
