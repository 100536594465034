import { template as template_730af11f985d472d8625629989ff68ad } from "@ember/template-compiler";
const WelcomeHeader = template_730af11f985d472d8625629989ff68ad(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
