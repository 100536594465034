import { template as template_ecfa3ca2179b4496a93fce2e5946f04e } from "@ember/template-compiler";
const FKLabel = template_ecfa3ca2179b4496a93fce2e5946f04e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
