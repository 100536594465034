import { template as template_412711437e044026abf68fce7d0d3e78 } from "@ember/template-compiler";
const FKText = template_412711437e044026abf68fce7d0d3e78(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
